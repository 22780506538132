import { SiteLocale } from '../types';

const links = {
  TERMS_CONDITIONS: {
    [SiteLocale.FI]: 'https://alvarpet.com/tilaus-ja-toimitusehdot/',
    [SiteLocale.EN]: 'https://alvarpet.com/en/terms-and-conditions/',
    [SiteLocale.DA]: 'https://alvarpet.com/da/terms-and-conditions-dk/',
    [SiteLocale.SV]: 'https://alvarpet.com/sv/terms-and-conditions-se/',
  },

  PRIVACY_POLICY: {
    [SiteLocale.FI]: 'https://alvarpet.com/tietosuojaseloste/',
    [SiteLocale.EN]: 'https://alvarpet.com/en/privacy-policy/',
    [SiteLocale.DA]: 'https://alvarpet.com/da/fortrolighedspolitik/',
    [SiteLocale.SV]: 'https://alvarpet.com/sv/integritetspolicy/',
  },
  CONSULTATION: {
    [SiteLocale.FI]: 'https://calendly.com/alvar-henna/ruokintaneuvonta',
    [SiteLocale.EN]: 'https://calendly.com/alvar-henna/ruokintaneuvonta',
    [SiteLocale.DA]: '',
    [SiteLocale.SV]: '',
  },
  FAQ: {
    [SiteLocale.FI]: 'https://alvar.gorgias.help/fi-FI',
    [SiteLocale.EN]: 'https://alvar.gorgias.help',
    [SiteLocale.DA]: 'https://alvar.gorgias.help/da-DK',
    [SiteLocale.SV]: 'https://alvar.gorgias.help/sv-SE',
  },
  MAIL_TO_SUPPORT: {
    [SiteLocale.FI]: 'mailto:hello@alvarpet.com',
    [SiteLocale.EN]: 'mailto:hello@alvarpet.com',
    [SiteLocale.DA]: 'mailto:hello@alvarpet.com',
    [SiteLocale.SV]: 'mailto:hello@alvarpet.com',
  },
  TRUSTPILOT_REVIEW: {
    [SiteLocale.FI]: 'https://fi.trustpilot.com/evaluate/alvarpet.com',
    [SiteLocale.EN]: 'https://trustpilot.com/evaluate/alvarpet.com',
    [SiteLocale.DA]: 'https://dk.trustpilot.com/evaluate/alvarpet.com',
    [SiteLocale.SV]: 'https://se.trustpilot.com/evaluate/alvarpet.com',
  },
  CONTACT: {
    [SiteLocale.FI]: 'https://alvarpet.com/alvarin-tarina/ota-yhteytta/',
    [SiteLocale.EN]: 'https://alvarpet.com/en/alvars-story/get-in-touch/',
    [SiteLocale.DA]: 'https://alvarpet.com/da/mere-om-alvar/kontakt-os/',
    [SiteLocale.SV]: 'https://alvarpet.com/sv/om-alvar/ta-kontakt/',
  },
  FACEBOOK: {
    [SiteLocale.FI]: 'https://www.facebook.com/alvarpet',
    [SiteLocale.EN]: 'https://www.facebook.com/alvarpet',
    [SiteLocale.DA]: 'https://www.facebook.com/alvarpet.dk',
    [SiteLocale.SV]: 'https://www.facebook.com/alvarpet.se',
  },
  INSTAGRAM: {
    [SiteLocale.FI]: 'https://www.instagram.com/alvarpet/',
    [SiteLocale.EN]: 'https://www.instagram.com/alvarpet/',
    [SiteLocale.DA]: 'https://www.instagram.com/alvarpet.dk/',
    [SiteLocale.SV]: 'https://www.instagram.com/alvarpet.se/',
  },
  MARKETING_SITE: {
    [SiteLocale.FI]: 'https://www.alvarpet.fi',
    [SiteLocale.EN]: 'https://www.alvarpet.com',
    [SiteLocale.DA]: 'https://www.alvarpet.dk',
    [SiteLocale.SV]: 'https://www.alvarpet.se',
  },
  COMPENSATED_PAW_PRINT_BADGE: {
    [SiteLocale.FI]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Compensated-Pawprint-FI.svg',
    [SiteLocale.EN]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Compensated-Pawprint-EN.svg',
    [SiteLocale.DA]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Compensated-Pawprint-DK.svg',
    [SiteLocale.SV]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Compensated-Pawprint-SE.svg',
  },
  REDUCED_PAW_PRINT_BADGE: {
    [SiteLocale.FI]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-outline-FI.svg',
    [SiteLocale.EN]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-outline-EN.svg',
    [SiteLocale.DA]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-outline-DK.svg',
    [SiteLocale.SV]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-outline-SE.svg',
  },
  REDUCED_PAW_PRINT_BADGE_FILLED: {
    [SiteLocale.FI]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-filled-FI.svg',
    [SiteLocale.EN]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-filled-EN.svg',
    [SiteLocale.DA]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-filled-DK.svg',
    [SiteLocale.SV]:
      'https://storage.googleapis.com/alvar-public/compensated-pawprint/Reduced-Pawprint-filled-SE.svg',
  },
  COMPENSATION_PAGE: {
    [SiteLocale.DA]: 'https://alvarpet.com/da/hundes-co2-poteaftryk/',
    [SiteLocale.EN]: 'https://alvarpet.com/hiilitassunjalki/',
    [SiteLocale.FI]: 'https://alvarpet.com/hiilitassunjalki/',
    [SiteLocale.SV]: 'https://alvarpet.com/sv/kilmattassavtryck/',
  },
};

export default links;
