export default {
  addons: {
    add_to_order: 'Lägg till i order',
    title: 'Glöm inte dessa!',
  },
  button_text: {
    browse_all_products: 'Se alla produkter',
    selected: 'Vald',
    select_product: 'Välj',
    faq: 'Vanliga frågor',
    cancel: 'Avbryt',
    continue: 'fortsät',
    product_details: 'Detaljer',
    sign_in: 'Logga in',
    sign_up: 'Skapa konto',
    view_alternatives: 'Visa alternativ',
    add_to_subscription: 'Lägg till prenumeration',
    updating_subscription: 'Uppdaterar beställning',
    change_delivery_schedule: 'Ändra leveransschema\n',
    order_overview: 'Beställningsöversikt\n',
    skip_next_order: 'Hoppa över nästa leverans',
    click_to_add_coupon: 'Lägg till rabattkod',
    configure: 'Ändra',
    edit_subscription: 'Ändra i beställningen',
    read_more: 'Läs mer',
    confirm: 'Bekräfta',
    view_change_product: 'Mer info/byt produkt',
    reactivate_subscription: 'Aktivera prenumeration',
  },
  campaign: {
    share_referral: {
      copy: 'Kopiera din länk och dela den',
      success: 'Kopierat! Redo att delas.',
    },
    action: {
      add_to_order: 'Lägg till i order',
    },
  },
  campaigns: {
    referral: {
      body: 'Hjälp oss växa genom att rekommendera Alvar till dina hundkompisar. Dela din personliga länk och både du och din vän får 100kr! Läs hur det funkar <a href=\\"/benefits/referral\\">här</a>", title: "Sprid budskapet 💕',
    },
    valentine: {
      disclaimer:
        'Vi bjuder på vårt favoritgodis, torkad fisk, till Alla Hjärtans Dag. För att din päskling förtjänar det lilla extra!',
      title: 'Gratis Alla ❤️-dag godis!',
    },
    tags: {
      limited_time: 'Finns endast i en begränsad period',
      new: 'Nytt från Alvar',
      'puppybox-dk': {
        upgrade_for_price: 'Uppgradera för 100 DKK',
      },
      'puppybox-se': {
        upgrade_for_price: 'Uppgradera för 100 SEK',
      },
      puppybox: {
        upgrade_for_price: 'Uppgradera för 10€',
      },
    },
    chews_promo: {
      disclaimer:
        'Prova våra nordiska tuggben! Alla våra tugg är 100% naturliga och baserat på vilt från Nordens skogar och sjöar',
      title: 'Något att tugga på?',
    },
    free_fish_oil: {
      disclaimer:
        'Just nu bjuder vi på en fiskolja värd 119kr när du beställer ett skräddarsytt foderpaket till din hund. Gäller för beställningar över 200kr.',
      title: 'Fiskolja på köpet',
    },
  },
  cancel_subscription: {
    allergic_reaction: {
      body_1:
        'Foderallergi är vanligtvis orsakat av ett specifikt protein i fodret. När du väljer foder till din hund kan det vara en bra idé att titta efter mer innovativa proteinkällor som hunden inte ätit förut - vad sägs om insektsprotein?',
      heading: 'Oroa dig inte, vi ska fixa detta!',
    },
    condolence: {
      heading: 'Vi är så ledsna över att höra det. 💔',
      body: '"<p>Det enda dåliga med hundar är att deras liv är för kort. Vi har avslutat din prenumeration och vi skickar våra varmaste tankar!\n</p>\n<p>- dina vänner på Alvar"',
    },
    consultation_link_didn_t_open:
      "Om kalendern inte öppnades, klicka <a href='https://calendly.com/alvar-henna/ruokintaneuvonta'>här.</a>\\n",
    email_consultation_success: 'Tack',
    i_want_still_cancel: 'Jag vill fortfarande avsluta',
    not_tasty: 'Min hund tyckte inte det var gott',
    sorry_but_why: {
      body: 'Vi är ledsna att höra att du vill avsluta {{dogName}}s prenumeration. snälla berätta varför så att vi kan fortsätta förbättra Alvar!',
      heading: 'Åh neeej - men varför?',
    },
    thanks_for_your_trust: 'Tack för ditt förtroende',
    vet_advised_change: {
      body: 'Vi har kanske en liknande lösning för din hund. Skulle du vara intresserad av vår rådgivning?',
      heading: 'Vi hoppas att du hittar ett lämpligt foder för {{dogName}}\\n',
    },
    we_will_send_your_consultation:
      '<p>Vi kommer skicka en rekommendation så snart som möjligt!</p><p>Kom ihåg att du kan senarelägga din nästa leverans om du vill vänta på resultatet från konsultationen.</p>',
    worse_poop: {
      body: 'Ibland när man ändrar diet, särskilt om fodret har en annan foberkomposition, så kan det leda till att förändrad kvalitet på avföringen, detta brukar gå över. Tålamod är viktigt - annars har vi andra foder som kanske passar din hund bättre.',
      heading: 'Oroa dig inte, vi ska fixa detta!',
    },
    you_can_always_make_changes:
      'Du kan alltid ändra din beställning. Låt oss gärna veta om det är något du behöver hjälp med!',
    consultation_awaits: 'Boka en konsultation!',
    need_more_time: 'Jag behöver mer tid för att prova',
    need_more_time_lead:
      'Testar ni fortfarande fodret? Låt oss skjuta upp nästa leveransdatum så att ni har mer tid att prova. ',
    request_failed: {
      text: 'Vänligen försök igen senare eller kontakta vår kundservice!',
      title: 'Ett fel uppstod.',
    },
    unhappy_with_service: 'Jag är inte nöjd med tjänsten',
  },
  delivery_prices: {
    price_over_x_money: '{{discounted}} för beställningar över {{spend}}\\n',
  },
  discount: {
    applies_to_all: '{{discount}} rabatt på alla produkter\\n',
    applies_to_categories: '{{discount}} rabatt på utvalda {{categories}}',
    product_category: {
      '2262': 'torrfoder',
      '3211': '',
      '4567': 'toppers',
      '4933': '',
      '5011': 'tugg',
      '5013': 'tugg',
      '5015': 'hundbajspåsar',
      '5021': 'godis',
      '5023': 'godis',
      '5029': 'torrfoder',
      '5031': 'torrfoder',
      '5035': 'oljer',
      '5037': 'oljer',
      '5041': 'toppers',
      '5043': 'toppers',
      '5183': 'tillbehör',
      '6540': 'finsk torrfoder',
      '6705': 'torrfoder',
      '7029': '',
      '7671': 'toppers',
      '7677': 'godis',
      '7681': 'tugg',
      '7687': 'tillbehör',
      '7693': 'torrfoder',
      '7697': 'tillskott',
      '7814': 'tillbehör',
      '7818': 'tillskott',
      '8262': 'kombos',
      '8264': 'kombos',
      '8266': 'kombos',
      '8349': '',
      '8383': 'leksaker',
      '8386': 'leksaker',
      '8388': 'leksaker',
      '8747': '',
      '8749': '',
      wetFood: 'toppers',
      generic_categories: 'produkter',
      snack: 'hundgodis',
    },
    applies_to_category: 'Rabatten gäller för {{category}}\n',
    applies_to_multiple_product_names:
      '{{discount}} rabatt på {{productList}}. Rabatten gäller upp till {{productLimit}} produkter i din din nästa leverans.\n',
  },
  discounts: {
    dog_names_discounts: '{{dogName}}s rabatter',
  },
  free_shipping: {
    included: '<b>GRATIS LEVERANS INKLUDERAT</b>',
    over_x_money: '<b>GRATIS LEVERANS</b> på beställningar över {{money}}',
    available:
      '<b>Fri frakt finns tillgänglig, gå till din profil för att byta leveransmetod </b>',
  },
  frontpage_notification: {
    shipping_price_changes: {
      banner:
        'Förändringar i leveranspriser! Justera leveransmetoden för att behålla fri frakt. Läs mer',
    },
  },
  label: {
    choose_product: 'Välj produkt',
    delivery: 'Leverans',
    price: 'Pris',
    product_category: 'Produktkategori',
    quantity: 'Antal',
    size: 'Storlek',
    variant: 'Model',
  },
  my_pack: {
    get_recommendation: 'Se rekommendation och prenumerera',
    recommended_diet_plan: {
      explanation:
        'Näringsrikt och hållbart! 💚 Kom ihåg att uppdatera eventuella förändringar i  {{dogName}}s behov!',
      header: '{{dogName}}s skräddarsydda foderplan\\n',
    },
    recommended_products: 'Rekommendationer',
  },
  nutritions_daily_title: 'Dagligt intag: {{dailyGrams}} g / {{dailyDl}} dl',
  orders: {
    load_all_receipts: 'Visa alla',
  },
  payment: {
    method: {
      kco: 'Klarna',
      missing: 'Betalmetod saknas',
      stripe: 'Kredit- eller betalkort (stripe)',
      manual: 'Betalmetod saknas',
    },
  },
  price: {
    free: 'GRATIS',
    total: 'Totalt ',
    from_per_kg: 'Från {{price}}/kg',
    starting_from: 'från {{price}}',
    from: 'från {{price}}',
  },
  product_info: {
    benefits: 'Hälsofördelar',
    carbon_pawprint: 'CO2 Tassavtryck',
    ingredients: 'Ingredienser',
    nutrients: 'Näringsinformation',
    complexes: 'Hälsofördelar',
    feeding_guide: 'Utfodringsguide',
  },
  recipe: {
    all_ingredients: {
      title: 'Alla ingredienser',
    },
    nutritional_content: 'Komplett näringsinformation',
    additional_benefits_title: 'Fodret är också bra för:',
    needs_covered_by_benefits_title: 'Fodret är bra för:',
  },
  recommendation_group: {
    good: 'Passande alternativ',
    notSuitable: 'Inte rekommenderat',
    recommended: 'Vår rekommendation',
  },
  subscription_details: {
    included_products: 'Produkter\\n',
    plus_shipping: '+ Leverans\\n',
  },
  targeted_upsell: {
    section_heading: 'Speciellt utvalda för {{dogName}}: ',
  },
  benefits: {
    rewards: {
      claim: 'Använd',
      claimed: 'Använd',
      order: 'Beställning',
      order_0: 'Välkomstgåva',
      reward_information:
        'Alvars trogna vänner får chansen att prova våra produkter gratis. 💚 Om du beställer regelbundet får du lojalitetsförmåner. Produkten du väljer läggs till i din nästa beställning. Förmånen aktiveras i din nästa leverans. ',
      reward_terms: 'Villkor för bonuspoäng',
      rewards_for_dog_name: "{{dogName}}'s Alvar-förmåner\\n",
      timeline_title: 'Din bonushistorik',
      you_have_x_unclaimed_rewards: 'Du har {{count}} olösta förmån(er)',
      coming_up: 'Kommer snart',
      youre_here: 'Du är här:',
      next: 'Kommande',
      previous: 'Tidigare',
      activation_callout:
        'För att ta del av och använda förmåner för dina beställningar, behöver du ha en aktiv prenumeration.',
      new_reward_in_order: 'beställning',
    },
    page_header: 'Förmåner som Alvarkund',
    navigation_option: {
      coupon: 'Rabatter',
      referral: 'Värvningsrabatt',
      rewards: 'Bonusar',
    },
    coupon: {
      explainer:
        'Aktivera rabattkoden i fältet ovan för att få rabatt på nästa order. Vi skickar bra erbjudanden och rabatter till alla trogna kunder- håll därför koll på din inkorg!',
      header: 'Har du en rabattkod?',
    },
    referral: {
      activation_callout:
        'Hallå där! 😍 För att ta del av bonusar du får genom att rekommendera Alvar, behöver du ha en aktiv prenumeration. ',
    },
  },
  checkout: {
    account_info: 'Kontoinformation',
    add_payment_methods: 'Lägg till betalningsinformation',
    add_shipping_methods: 'Lägg till leveransinformation',
    delivery_method: 'Leveransmetod',
    delivery_method_text: 'Hur och vart vill du ha din order levererad?',
    do_order: 'Order',
    order: 'Order',
    payment_info: 'Betalningsinformation',
    shipping_info: 'Leveransinformation',
    add_shipping_method: 'Lägg till leveransinformation\n',
    account: 'Skapa konto',
    account_set_up: 'Skapa konto',
    verify_shipping_info: 'Bekräfta adress',
    confirm_subscription: 'Till beställning',
  },
  delivery: {
    free: 'GRATIS',
    demo_shipping_price: 'Leverans',
    checking_availability: 'Söker möjliga alternativ',
    free_delivery_on_orders_over_spend:
      'Gratis leverans på beställningar över {{spend}}',
    option_not_available:
      'Leveransalternativet är inte tillgänligt för vald postkod',
    regular_price_for_subs_under_x:
      'Gratis leverans på prenumerationer över {{minimumSpend}} efter provpaketet.\n',
  },
  edit_order: {
    quantity_higher_than_recommended:
      'Vänligen notera att din beställda mängd är högre än rekommenderat.',
    quantity_lower_than_recommended:
      'OBS! Din ordermängd är lägre än vad som är rekommenderat',
  },
  emission_card: {
    header: 'Foderplanens klimatpåverkan',
    kg_saved_per_year_with_subscription: '{{dogName}} sparar {{kg}}kg CO2e',
    percent_saved:
      'Det är <b>{{percent}}% mindre </b>än ett genomsnittligt premiumtorrfoder. <br/>Vi kompenserar för de utsläpp som ännu bli kvar.',
    savings_equal_to_times_sauna:
      'årligen med sin foderplan. Det motsvarar uppvärmningen av en elektrisk bastu {{timesSauna}} gånger!',
    by_joining_alvar_dog_name_saves_kg:
      'Med Alvar sparar du och {{dogName}} ca.',
    kg_co2_per_year: '{{kg}} kg CO2 per år',
    this_equals_times_sauna:
      'Det motsvarar att värma upp en elektrisk bastu {{timesSauna}} gånger.',
  },
  form: {
    contact: {
      submit: 'Skicka',
      success: {
        heading: 'Tack!',
        message:
          'Tack, vi kommer att svara på ditt meddelande så snart som möjligt. Skicka helst inte samma meddelande till oss igen, i ett försök att snabba på processen, om inte det är något du glömt att meddela oss, så klart! Vi har stor respekt för alla funderingar, frågor, förslag och önskemål eftersom de hjälper oss att förbättra Alvars tjänst.',
      },
    },
    placeholder: {
      autocomplete: 'Skriv här',
      autocomplete_disabled: 'Min hundras finns inte',
      birthday: 'dd.mm.yyyy',
      custom_breed_name: 'Saknar ras',
      day_format: 'DD',
      email: 'exempel@email.se',
      month_format: 'MM',
      select: 'Välj...',
      year_format: 'ÅÅÅÅ',
      coupon_field: 'Rabattkod',
    },
    labels: {
      email: 'E-post',
    },
    warning: {
      unsaved_changes:
        'Du har inte sparat ändringarna! Är du säker på att du vill lämna sidan?',
    },
    errors: {
      invalid_phone_code: 'Fel landskod',
      dog_weight: {
        too_high: 'Hundens vikt får ej överstiga 200 kg',
        too_low: 'Hundens vikt måste vara över 0',
      },
      email_already_in_use: 'Den här epostadressen används redan',
      email_already_in_use_please_login:
        'Den här epostadressen används redan - vänligen logga in',
      invalid_date: 'Ogiltigt datum',
      invalid_day: 'Ogiltig dag',
      invalid_month: 'Ogiltig månad',
      month_only_has_x_days: 'Den angivna månaden har endast {{days}} dagar',
      must_be_number: 'Måste vara numeriskt',
      no_negative_allowed: 'Värdet kan inte vara negativt',
      no_options_available: 'Tyvärr, inga passande alternativ hittades.',
      not_valid_email: 'Kolla din inkorg',
      not_valid_postcode: 'Fel postnummer',
      passwords_not_match: 'Lösenorden stämmer ej överens',
      postal_code_needed: 'Postnummer är obligatoriskt',
      postcode_needed: 'Postnummer är obligatoriskt',
      required: 'Obligatorisk',
      sum_too_high: 'Summan kan ej överskrida 100%',
      too_short_password: 'Lösenordet är för kort',
      must_include_diet_item: 'Minst en kategori av foder måste vara vald.',
    },
    delivery: {
      matkahuolto: 'Matkahuolto',
      post: 'Postombud',
      submitted_notification: 'Sparat!',
      home: 'Hemleverans',
      bring: 'Bring',
      budbee: 'Budbee',
      postnord: 'PostNord',
      instabox: 'Instabox',
    },
    submission_failed:
      'Ett fel uppstod! Vänligen försök igen eller kontakta vår kundservice om du inte kommer vidare.',
    submit_new_password: 'Skicka ett nytt lösenord',
    loading: 'Laddar alternativ...',
    confirm: 'Bekräfta',
  },
  forms: {
    errors: {
      invalid_phone_number: 'Fel telefonnummer',
    },
  },
  labels: {
    billing_method: 'Betalningsmetod',
    drop_point: 'Postombud',
    email_user_account: 'E-post (användarprofil)',
    last_name: 'Efternamn',
    name: 'Namn',
    password: 'Lösenord',
    password_repeat: 'Upprepa lösenord',
    phone_prefix: 'Landskod',
    shipping_address: 'Adress',
    shipping_city: 'Stad',
    shipping_country: 'Land',
    shipping_postcode: 'Postnummer',
    starting_date: 'Nästa order efter provpaketet',
    contact_info: 'Kontaktinformation',
    interval: 'Leveransintervall',
    invoices: 'Kvitton',
    payment_info: 'Betalningsinformation',
    shipping_info: 'Leveransinformation',
    delivery: {
      email_opt_in:
        'Woof yes! Jag vill gärna få ta del av grymma erbjudanden från Alvar',
    },
    save_dog_info: 'Spara mina uppgifter och skapa konto',
    email_opt_in:
      'Woof yes! Jag vill gärna få ta del av grymma erbjudanden från Alvar',
    email: 'E-post',
    country: 'Skickas till',
    delivery_pickup_point: 'Postombud',
    shipping_interval: 'Leveransintervall',
    shipping_size: 'Mängd torrfoder per leverans',
    delivery_type: 'Leveranstyp',
    shipping_date: 'Nästa order packas\n',
    first_name: 'Förnamn',
    home_delivery: 'Fraktalternativ',
    phone: 'Telefonnummer',
    delivery_address: 'Leveransadress',
    card: {
      card_number: 'Kortnummer',
      cvn: 'CVN',
      expiration: 'Utgångsdatum',
    },
    bundle_size: 'Paketstorlek',
    current_password: 'Nuvarande lösenord',
    delay_next_order_modal: {
      interval: 'Leveransintervall',
      payment_date: 'Nästa order:',
    },
    new_password: 'Nytt lösenord',
    new_password_repeat: 'Upprepa nytt lösenord',
    address: 'Adress',
    city: 'Stad',
    po_box: 'Postombud',
    zip: 'Postnummer',
  },
  links: {
    edit: 'Ändra',
    show_all: 'Visa alla',
    save: 'Spara',
    view_products: 'Se produkter',
  },
  macros: {
    fat: 'Fett',
    fiber: 'Fiber',
    kcal: 'Kcal',
    protein: 'Protein',
  },
  macro: {
    fat: 'Fett',
    fiber: 'Fiber',
    protein: 'Protein',
    energy: 'kcal / 100g\n',
    kcal: 'kcal / 100g\n',
  },
  order_hero: {
    button_text: {
      edit_order: 'Ändra i ordern',
    },
    status: {
      active: 'Aktiv',
    },
  },
  order: {
    add_treat: 'Vad sägs om dessa?',
    configure_dog_names_subscription: '{{dogName}}s <b>Prenumeration</b>',
    demo_price: 'Pris för prova på paketet',
    dog_names_demo_order: '{{dogName}}s <b>prova-på-paket</b>',
    next_delivery: 'Nästa order packas',
    price_and_then: 'Och därefter, om du väljer att fortsätta prenumerationen:',
    sample_total: 'Prova på paket totalt',
    subscription_intro:
      'När du beställer ett provpaket aktiveras en helt bindingsfri prenumeration. Du kan när som helst ändra eller avbryta din prenumeration. Obs! Val av datum nedan gäller för din efterföljande order – prova på paketet levereras inom ett par dagar oberoende av detta datum.',
    download_receipt: 'Ladda ner kvitto',
    errors: {
      something_went_wrong: 'Oj, vi är hemskt ledsna - något gick fel!',
    },
    next_delivery_for_dog_name: '{{dogName}}s nästa order',
    order_history: 'Ordrar',
    orders: 'Prenumeration',
    you_can_edit_order_until: 'Ändra i ordern fram till {{untilDate}}',
    notifications: {
      subscription_discount:
        'Värvningsfördel! Du får rabatt på din näste order',
    },
    coupon_confirm: 'Bekräfta',
    coupon_label_1: 'Har du en rabattkod?',
    coupon_label_2: 'Lägg till koden',
    completed: 'Genomförd',
    history_product_plural: 'produkter',
    total: 'Prenumeration totalt:',
    subscription_intro_name_grams:
      '{{dogName}} bör äta {{dailyGrams}}g torrfoder per dag, så vi tycker att det här är ett passande intervall. Men du kan själv justera som du vill, när du vill!',
    on_hold: 'Pausa prenumeration',
    every_x_weeks: 'Varje {{weeks}} vecka\n',
    interval: 'Leveransintervall',
    per_x_week: 'Var {{weeks}} vecka',
    this_order: 'Denna beställning',
    delivery_interval: 'Leverans var {{interval_weeks}} vecka',
    history_product: 'produkt',
  },
  page_title: {
    '404': ' ',
    sustainability: 'Min flocks klimatinsats',
    order: 'Beställ',
    wrapped: 'Ditt År wrapped',
    benefits: {
      coupon_page: 'Rabatter',
      frontpage: 'Alvars förmåner',
      referral_page: 'Tipsbonusar',
      rewards_page: 'Bonusar',
    },
    breeders: {
      benefits_page: 'Förmåner för uppfödare',
      frontpage: "Alvars Breeder's Club",
      puppies_page: 'Är det valpar på gång?',
    },
    frontpage: 'Kundsidan',
    my_herd: {
      edit: 'Ändra',
      frontpage: 'Min flock',
    },
    orders: {
      edit: 'Ändra',
      frontpage: 'Beställningar',
    },
    profile: {
      edit_payment: 'Ändra',
      edit_shipping: 'Leveransadress',
      edit_user: 'Ändra i din profil',
      frontpage: 'Profil',
    },
    recommendation_machine: 'Foderguiden',
  },
  product_card: {
    about_product: 'Produktinformation',
    more_information: 'Mer om fodret',
  },
  product_options: {
    recommended: 'rekommenderas',
  },
  product: {
    clearance_nuggets: {
      long: 'Klimatdeals: Nuggets',
      short: 'Nuggets',
    },
    clearance_fish_oil: {
      short: 'Klimatdeals: Vildfiskolja',
      long: 'Klimatdeals: Alvars Vildfiskolja 100 ml',
    },
    info_text: 'Produktinformation',
    puppy_box: 'Alvars valpbox',
    'zero-emission': {
      accessory: 'Tillbehör',
      dryFood: 'Alvars nollutsläppstorrfoder',
      kibble: 'Alvars nollutsläppstorrfoder',
      puppyBox: 'För valpar',
      sauce: 'Alvars kosttillskott med nollutsläpp',
      snack: 'Alvars hundgodis med nollutsläpp',
      supplement: 'Alvars kosttillskott med nollutsläpp',
      wetFood: 'Alvars topper med nollutsläpp',
      chew: 'Alvars tuggben',
      toy: 'Alvars Upcycled Toy',
      treat: 'Alvars hundgodis',
    },
    bug_sample: 'Bug',
    serene_sample: 'Serene',
    slender_sample: 'Slender',
    zippy_sample: 'Zippy',
    german_fish_and_chips: {
      long: 'Klimatdeals: Fish & Chips',
      short: 'Fish & Chips',
    },
    steady_sample: 'Steady',
    root_sample: 'Root',
    out_of_stock: 'Tillfälligt slut',
    view: 'Mer info',
  },
  'puppy-box-423575': 'Fisk',
  'puppy-box-423589': 'Kyckling',
  'puppy-box-423651': 'Kyckling',
  'puppy-box-423652': 'Fisk',
  'puppy-box-423838': 'Fisk',
  'puppy-box-423857': 'Kyckling',
  recommendation: {
    manage_secondary: 'Ändra/Ta bort',
    feeding_guide: {
      heading: 'Utfodringsguide',
      calorie_overview: {
        heading: 'Fördelning av kalorier',
      },
      lead: 'Rekommenderad sammansättning och mängd som {{dogName}} bör äta dagligen för att fortsätta vara glad och hälsosam.',
      macros: {
        heading: 'En överblick',
      },
    },
    diet_plan_intro: {
      heading: '{{dogName}}s skräddarsydda rekommendation',
      lead: 'Här är vår rekommendation, skräddarsydd utifrån {{dogName}}s behov. Du kan ändra innehållet i prova på paketet i nästa steg.',
    },
    consideration_slide: {
      activity: {
        active:
          'Vi kommer att anpassa daglig fodergiva för att passa {{dogName}}s aktiva livsstil.',
        lazy: 'Vi kommer att anpassa daglig fodergiva för att passa {{dogName}}s lugna livsstil.',
        normal:
          'Vi kommer att anpassa daglig fodergiva för att passa {{dogName}}s livsstil.',
      },
      age: {
        adult: 'Vi skapar en rekommendation som passar {{dogName}}s behov.',
        puppy:
          'Vi kommer att rekommendera foder speciellt framtagna för valpars behov',
        senior:
          'Vi kommer att rekommendera foder speciellt framtagna för äldre hundars behov',
      },
      bcs: {
        chubby:
          'För att hjälpa {{dogName}} med viktkontroll kommer vi att rekommendera foder med lågt energiinnehåll.',
        ideal:
          '{{dogName}} kommer få alla behov uppfyllda från naturliga, högkvalitativa ingredienser.',
        skinny:
          'För att hjälpa {{dogName}} att hålla vikten, kommer vi att rekommendera recept med högt energiinnehåll.',
      },
      special_need: {
        heartBurn:
          'För att undvika halsbränna, kommer vi att rekommendera ett recept till {{dogName}}, som har lågt fettinnehåll',
        neutered:
          'Eftersom {{dogName}} är kastrerad kommer vi ta hänsyn till det i val av recept och fodermängd',
        pickyEater:
          'Eftersom {{dogName}} är kräsen så kommer vi addera lite extra YUM i rekommendationen.',
      },
    },
    pages: {
      ingredients: {
        disclaimer:
          'Vi har kanske ingen monoproteinblandning än som passar {{name}} - men vi ska göra vårt bästa!',
      },
      email: '{{dogName}}, din rekommendation är klar!',
      email_loading: 'Skapar {{dogName}}s foderplan..',
      activity: 'Aktivitet',
      diet_composition: 'Foderandel',
      dog_info: 'Info om din hund',
      ingredients_page_title: 'Önskade ingredienser',
      recommendation: 'Vår rekommendation',
      specialNeeds: 'Särskilda behov',
    },
    diet_plan_heading: '{{dogName}}s skräddarsydda foderplan',
    diet_plan_lead:
      '<b>Här är den, Din hunds näringsmässigt optimala och klimatvänliga dagliga foderrekommendation.</b> 😍🐾 Du kan utforska och ändra produkterna nedan.',
    show_recommendation: 'Visa rekommendation',
    form: {
      is_breeder: {
        tooltip:
          'Är du uppfödare? Gå med i Alvars klubb för uppfödare. Vi skickar gärna information till dig per e-post',
      },
      is_breeder_label: 'Jag är uppfödare',
      demo_order: 'Nästa',
      terms_conditions_link: 'Allmänna villkor',
      terms_conditions: 'Jag godkänner',
    },
    restart_confirmation: 'Återställ foderguiden',
    session_expired:
      'Din session har gått ut. Vänligen ange din e-postadress igen.',
    subscription_ksp: {
      body: {
        home_delivery: 'Njut av bekvämligheten från regelbundna leveranser',
        loyalty_benefits: 'Vi kommer skämma bort dig med erbjudanden och gåvor',
        modify_cancel_any_time:
          'Ändra, skjut upp eller avsluta din prenumeration när du vill',
        lower_prices: 'Prenumeranter får alltid <b>10% rabatt</b>.',
      },
      heading: {
        home_delivery: 'Direkt hem till dörren',
        loyalty_benefits: 'Mer än att bara klia bakom örat ',
        modify_cancel_any_time: 'Flexibelt efter dina behov',
        lower_prices: 'De bästa priserna till bästa vänner',
      },
      section_heading: 'Fördelar med prenumeration',
    },
    referral_link_text: 'Ge 100kr - Få 100kr',
    thank_you:
      '<p>Vi är så glada att ni valt att vara med på vår resa för att minska CO2-tassavtrycket. Tillsammans har vi redan sparat mer än 2.810.632 kg CO2e\n</p> <p><b>Vill ni hjälpa oss att växa och bjuda in era hundvänner på resan?\n</b></p>',
    thank_you_add_another_notice: ' ',
    dog_names_meal_plan_product_count:
      'Vi har skapat en foderplan till {{dogName}}, som består av följande {{productCount}} produkter.\n',
    order_overview: 'Gå till beställning',
    our_recommendation: 'Vår rekommendation',
    thank_you_add_dog: 'Lägg till ytterligare en hund',
    wet_food_feeds_other: {
      'ALV-711':
        'Chicken Bowl innebär en smaskig variation till måltiderna för en hund som {{dogName}} som är van att inte bara äta torrfoder.\n',
      'ALV-402':
        'Alvars kyckling innebär en smaskig variation till måltiderna för en hund som {{dogName}} som är van att inte bara äta torrfoder.',
    },
    cart_is_empty: {
      body: 'Klicka för att återställa din ursprungliga rekommendation\n',
      header: 'Varukorgen är tom\n',
    },
    change_interval: {
      explainer:
        'Leveras var {{weeks}} vecka<br/>Ändra eller avsluta när som helst.',
      made_to_fit: 'Anpassat till ditt schema',
      commitment_free: 'Din bindningsfria prenumeration',
      make_changes_any_time:
        'Pausa, ändra leveransdag eller avsluta när du vill',
    },
    reset_cart_to_recommendation: 'Återställ varukorg\n\n\n\n\n\n\n',
    secondary_product: {
      remove: 'Ta bort',
    },
    treats_feeds_treats: {
      'ALV-202':
        'Vi har hört att {{dogName}} är svag för godis. Alvars hälsosamma och smaskiga Nuggets är perfekta för träning eller för att skämma bort lite extra.',
    },
    wet_food_feeds_wet: {
      'ALV-402':
        'Kycklingtopper är ett smakrikt och nyttigt fodertillbehör för hundar som brukar äta blötmat med sitt torrfoder såsom {{dogName}}',
    },
    treats_dog_is_puppy: {
      'ALV-205':
        'Vid den här åldern så lär sig {{dogName}} konstant nya saker. Alvars hälsosamma och smaskiga Wild Deer treats är perfekta för valpens dagliga träning.\n',
    },
  },
  starting_price: {
    from_per_kilo: 'Från<br/>{{startingPrice}}<br/>/kg',
  },
  feedback: {
    labels: {
      message: 'Mitt meddelande',
      subject: 'Feedbackämne',
    },
    subjects: {
      delivery: 'Leverans',
      feedback: 'Feedback',
      feeding: 'Näring',
      order: 'Prenumeration',
      other: 'Annat',
      payment: 'Betalning',
      products: 'Produkter',
      reclamation: 'Reklamation',
    },
  },
  page_not_found: 'Tyvärr kunde vi inte hitta den begärda sidan',
  subscription: {
    coupon_field: {
      disabled_help_text:
        'Aktivera din prenumeration, för att kunna aktivera rabattkoden\\n',
    },
    next_order_comes: 'Nästa leverans för {{dogName}} kommer\\n',
    welcome_back: 'Välkommen tillbaka',
    add_treats: 'Lägg till extra godbitar och tillbehör',
    cancel: 'Avsluta prenumeration',
    delivery_and_payment: 'Leverans- & Betalningsmetod',
    dog_names_subscription: '{{dogName}}s <b>Prenumeration</b>',
    next_order_comes_after:
      'Nästa leverans till {{dogName}} skickas om {{days}} dagar! Du kan ändra i ordern fram till {{untilDate}}.',
    sub_total: 'Delsumma',
    summary: {
      delivery: 'Leverans',
      x_to_free_delivery: '<b>{{spend}}</b> till gratis leverans',
    },
    update: 'Uppdatera',
    update_success: 'Ändringar sparade',
    some_recent_order_in_completed_state:
      '{{dogName}} behöver inte vänta länge mer, leveransen är påväg! ',
    some_order_needs_payment:
      'Det uppstod ett problem med din beställning. Klicka här för att fortsätta.',
    some_recent_order_in_processing_state:
      '{{dogName}} behöver inte vänta länge mer, beställningen packas nu! ',
    welcome_back_name: 'Välkommen tillbaka, {{name}}!',
    activate: 'Aktivera din prenumeration',
  },
  thank_you: 'Tack',
  wrapped: {
    alvar_intro: {
      body: 'Tack för att du minimerat CO2-tassutsläppen med oss detta år. Vi är stolta att presentera din hunds CO2-besparingar.',
      heading: 'Furwell {{year}} 🐾',
    },
    dog_names_year_wrapped: '{{dogName}}s {{year}} wrapped',
    download_image: 'Ladda ner bilden',
    home_page_notification: {
      body: 'Nu när {{year}} kommer till ett slut, se hur mycket utsläpp du minskat detta år. 🐾',
      button: 'Få ditt {{year}} Wrapped',
      heading: "It's a wrap!",
    },
    how_did_we_calculate: {
      body: '<li>1. Mängden Alvar torrfoder {{dogName}} ätit under {{year}}. Rapporten gäller endast torrfoder - än så länge. </li> <li>2. Beräkning av fodrets CO2-utsläpp </li> <li>3. Jämförelse med utsläppen från ett genomsnittligt premiumtorrfoder (The global environmental paw print of pet food, Alexander et al. 2020) </li> <li>4. Jämförelse av minskade utsläpp med utsläppen från en elektrisk bastu (OpenCO2.net) </li> <br/><p>P.S. Vi sparar inte din bild någonstans. </p> <p>P.P.S. Gott nytt år! </p>',
      heading: 'Hur vi räknat:',
    },
    intro:
      'Lägg till en bild av din hund, spara bilden på din telefon och dela i social media (tagga oss gärna @alvarpet.se)!',
    sorry_no_orders:
      'Tyvärr kan vi inte hitta några beställningar för din hund under {{year}}.',
    upload_image: 'Lägg till en bild av din hund',
    upload_new_image: 'Byt bild',
  },
  errors: {
    dog_weight: {
      too_high: 'Hundens vikt får ej överstiga 200 kg',
      too_low: 'Hundens vikt måste vara över 0',
    },
    email_already_in_use: 'Den här epostadressen används redan',
    email_already_in_use_please_login:
      'Den här epostadressen används redan - vänligen logga in',
    invalid_date: 'Ogiltigt datum',
    invalid_day: 'Ogiltig dag',
    invalid_month: 'Ogiltig månad',
    month_only_has_x_days: 'Den angivna månaden har endast {{days}} dagar',
    must_be_number: 'Måste vara numeriskt',
    no_negative_allowed: 'Värdet kan inte vara negativt',
    must_feed_dry_food: 'translation missing',
    no_options_available: 'Tyvärr, inga passande alternativ hittades.',
    not_valid_email: 'Kolla din inkorg',
    not_valid_postcode: 'Fel postnummer',
    passwords_not_match: 'Lösenorden stämmer ej överens',
    postal_code_needed: 'Postnummer är obligatoriskt',
    postcode_needed: 'Postnummer är obligatoriskt',
    recommendation_engine_create_account_token_invalid:
      'Åh nej - din session har gått ut. Börja om från början, tack',
    recommendation_engine_create_account_token_invalid_link_text: 'börja om',
    required: 'Obligatorisk',
    sum_too_high: 'Summan kan ej överskrida 100%',
    too_short_password: 'Lösenordet är för kort',
  },
  warning: {
    diet_composition: {
      sum_exceeds_100: 'Summan av fodret överskrider 100%',
    },
    unsaved_changes:
      'Du har inte sparat ändringarna! Är du säker på att du vill lämna sidan?',
  },
  placeholder: {},
  dates: {
    date_no_future: 'Framtida datum är ej tillåtna',
    date_not_valid: 'Ej giltigt datum',
  },
  dog: {
    gender: {
      male: 'Hane',
      female: 'Tik',
    },
    diet_composition: {
      intro:
        'Standarden är 100% torrfoder. Du kan ändra foderkompositionen här nedan!',
    },
    intro_text:
      'Vi skräddarsyr en foderplan till ditt Prova-på-paket baserat på informationen',
    labels: {
      diet_items: '{{dogName}}s foderplan inkluderar för närvarande följande:',
      gender: 'Kön',
      special_needs: 'Särskilda behov',
      weight: 'Vikt för vuxen hund',
      birthday_field: {
        day: 'Dag',
        hint: 'Till exempel, 07 19 2019 (Som Alvar!)',
        legend_with_name: 'När är {{name}}s födelsedag?',
        legend_without_name: 'När är din hunds födelsedag?',
        month: 'Månad',
        year: 'År',
      },
      preferred_ingredients: 'Önskade ingredienser',
      activity: 'Daglig aktivitet',
      breed:
        'Ras (hittar du inte din hunds ras eller har en blandras så välj blandrashund)',
      body_condition: 'Kroppsbyggnad',
      dog_name: 'Hundens namn',
      diet_composition: {
        wet: 'Våtfoder (%)',
        treats: 'Godis (%)',
        other: 'Annat/färskfoder (%)',
        dry: 'Torrfoder (%)',
      },
      birthday: 'Födelsedag',
      account_action: 'Logga in eller skapa konto',
    },
    title: 'Hej, jag är Alvar! Berätta för oss om din hund!',
    titles: {
      activity: 'I vilken form är {{dogName}}?',
      body_type: 'Kroppstyp',
      diet_composition: 'Vilken fodersammansättning vill {{dogName}}s ha?',
      preferred_ingredients: 'Vad vill {{name}} ha i sin skål?',
    },
    update_success: 'Ändringarna är sparade!',
    placeholder: {
      weight_estimate: 'Vår beräkning',
      enter_weight:
        'Uppskattad vuxenvikt i hela kilo, ej i ett interval (en uppskattning räcker)',
      our_weight_estimate:
        'Ange uppskattad vuxenvikt i kilo, ange ej ett intervall (vår uppskattning är {{estimate}} kg)',
    },
    birthday_too_old: 'Hoppsan, din hund kan knappast vara {{age}} år gammal?',
    add_new: 'Lägg till ny',
    add_another: 'Lägg till ytterligare en hund',
    add: 'Lägg till hund',
    carbon_paw_print_title: '{{dogName}}s klimattassavtryck',
    body_type_modal: {
      header: 'Hur bedömer man hundens kroppstyp??',
      body: 'En hund med normal vikt/kroppstyp ska ha en tydligt synlig midja och revbenen ska lätt kunna kännas under pälsen. Ge din hund en kram!',
    },
    body_condition: {
      '3': 'Smal',
      '5': 'Normal',
      '7': 'Kraftig',
      choose: 'Välj',
    },
    label: {
      preferred_ingredients: 'Önskade ingredienser',
      diet_share_dryFood: 'Torrfoder',
      diet_share_other: 'Annat',
      diet_share_snack: 'Godis',
      diet_share_wetFood: 'Våtfoder',
      diet_share_treat: 'Godis\n',
    },
    no_special_needs: 'Inga särskilda behov',
  },
  nav: {
    home: 'Hem',
    marketing_site: 'Tillbaks till alvarpet.se',
    back_to_marketing_site: 'Tillbaks till alvarpet.se',
    recommendation: {
      confirm_navigation:
        'På väg att lämna foderguiden? Dina val kan gå förlorade 🐾',
    },
    benefits: 'Förmåner',
    breeders_club: 'För uppfödare',
    frontpage: 'Hem',
    login: 'Logga in',
    logout: 'Logga ut',
    my_herd: 'Min flock',
    orders: 'Prenumeration',
    products: 'Produkter',
    profile: 'Min profil',
    recommendation_engine: 'Foderguiden',
    recommendation_machine: 'Foderguiden',
    recommendation_machine_confirmation:
      'Du verkar inte vara färdig med foderguiden. Vill du börja om?',
    recommendation_machine_restart: 'Starta om på nytt',
    my_sustainability: 'Mitt CO2-tassavtryck',
  },
  loading: 'Hämtar pinnar och data 🐾',
  confirm: 'Bekräfta',
  action: {
    add_to_order: 'Lägg till i order',
    change_recipe: 'Ändra foder',
    add: 'Lägg till',
    change_dog: 'Välj annan hund',
    remove: 'Ta bort',
    go_to_next: 'Nästa',
    show_more: 'Visa fler',
  },
  image_alt_text: {
    activity_level_1: 'Inaktiv hund',
    activity_level_2: 'Medelaktiv hund',
    activity_level_3: 'Mycket aktiv hund',
    body_type_1: 'Smal',
    body_type_2: 'normal',
    body_type_3: 'Kraftig',
    preferred_ingredient: {
      chicken: 'Kyckling',
      duck: 'Anka',
      fish: 'Fisk',
      grainFree: 'Spannmålsfritt',
      insect: 'Insekter',
      noPreference: 'Ingen preferens',
      plant: 'Växtbaserat',
    },
    diet_item: {
      dryFood: 'Torrfoder',
      other: 'Annat',
      snack: 'Godis',
      wetFood: 'Våtfoder',
      treat: 'Godis',
    },
    illustrations: {
      home_delivery: 'hemleverans',
      loyalty_benefits: 'gåvor',
      modify_cancel_any_time: ' ',
      lower_prices: 'rabatter',
    },
  },
  hint: {
    diet_composition: {
      dietShareOther: 'Andel av fodret, annat',
    },
  },
  forgot_password: 'Har du glömt lösenordet?',
  validation_suggestion: {
    diet_composition: {
      reduce_to_match: 'Minska för att uppnå totalt 100%',
    },
  },
  diet_legend: {
    dry: 'Torrfoder',
    other: 'Annat / Färskfoder',
    supplement: 'Kosttillskott',
    treats: 'Godis',
    wet: 'Våtfoder',
  },
  food: {
    nutritions: {
      recommended: {
        lead: '{{dogName}}s optimala, dagliga kost är {{dailyGram}} g torrfoder. Den är baserad på {{dogName}}s ålder, ras och livsstil.',
      },
    },
    preferred_ingredient: {
      chicken: 'Kyckling',
      duck: 'Anka',
      fish: 'Fisk',
      grain_free: 'Spannmålsfritt',
      insect: 'Insekter',
      no_preference: 'Ingen preferens',
      plant: 'Växtbaserat',
    },
    for_special_need: {
      activeDog: 'Aktiv hund',
      agingDog: 'Äldre hund',
      brainWellness: 'Mental hälsa',
      delicateStomach: 'Känslig mage',
      grainFree: 'Spannmålsfritt',
      greedyEater: 'Matglad hund',
      growingDog: 'Växande hund',
      itchyPaws: 'Kliande tassar',
      jointStiffness: 'Stela leder',
      loosePoop: 'Lös avföring',
      neutered: 'Kastrerad/Steriliserad',
      pickyEater: 'Kräsen',
      sensitiveSkin: 'Känslig hud',
      tendencyToGainWeight: 'Tendens till viktuppgång',
    },
    covers_x_weeks: 'räcker {{weeks}} veckor',
  },
  shipping_countries: {
    se: 'Sverige',
    no: 'Norge',
    fi: 'Finland',
    dk: 'Danmark',
    de: 'Tyskland',
  },
  units: {
    after_days: 'efter antal dagar',
    bottle: 'flaska',
    can: 'burk',
    dl: 'dl',
    from: 'från',
    grams: 'g',
    hour: 'h',
    kcal: 'kcal',
    kilos: 'kg',
    ml: 'ml',
    pcs: 'st',
    price_starting_abbr: 'från',
    product_plural: 'produkter',
    sample_order: 'prova på paket',
    product_singular: 'produkt',
    tbsp: 'matsked',
    week: 'veckor',
    weeks: 'veckor',
    weeks_abbr: 'veckor',
    x_pack: '{{x}}-pack',
  },
  wizard: {
    next: 'Nästa',
    submit: 'Skicka',
  },
  contact_info: {
    email: 'hello@alvarpet.com',
    phone_de: '+49 30 20995974',
    phone_dk: '+358 10 200 7350',
    phone_fi: '+358 10 200 7350',
    phone_no: '+358 10 200 7350',
    phone_se: '+358 10 200 7350',
    take_contact: 'Kontakta oss',
    take_contact_lead: 'Har du något på hjärtat? Frågor eller önskemål?',
  },
  channel: {
    email: 'Kontakta oss',
    facebook: '@alvarpet.se',
    instagram: '@alvarpet.se',
  },
  footer: {
    agb: 'Villkor',
    datenschutz: 'Integritet',
    disclaimer: '© {{year}} ALVAR PET OY. FOOD FOR THE CONSCIOUS PUP.',
    impressum: 'Sidansvariga',
    privacy_policy: 'Integritetspolicy',
    widerruf: 'Retur',
  },
  accessibility: {
    close_menu: 'Dölj meny',
    open_menu: 'Visa meny',
    open_options: 'Se alternativ',
  },
  navigation: {
    go_to_x: 'Gå till',
  },
  meta_description: {
    recommendation:
      'Få en skræddersyet foderplan tilpasset din hunds specifikke behov og se forskellen hos din hund.',
    frontpage: 'Logga in på din kundsida och hantera din beställining',
  },
  password: 'Lösenord',
  email: 'email',
  login: {
    login_in: 'Logga in',
    button: 'Logga in',
    log_in: 'Logga in',
    remember_me: 'Kom ihåg mig',
    errors: {
      login_failed: 'Inloggningen misslyckades. Vänligen försök igen.',
    },
  },
  puppy_box: {
    item: {
      tote_bag: 'Tygväska',
    },
    description:
      'En valpbox innehåller ett urval av våra produkter som hjälper valpar att få kontroll över sitt klimattassavtryck redan från start. \n\n',
  },
  product_category: {
    chew: 'Tugg',
    dryFood: 'Torrfoder',
    toy: 'Leksaker',
    long: {
      dryFood: 'Alvars torrfoder',
      chew: 'Alvars tuggben',
      accessory: 'Alvars tillbehör',
      supplement: 'Alvars oljer',
      treat: 'Alvars hundgodis',
    },
    accessory: 'Tillbehör',
    all: 'Alla',
    sauce: 'Tillskott',
    snack: 'Hundgodis',
    supplement: 'Oljor',
    wetFood: 'Våtfoder',
  },
  unit: {
    kg_co2e: 'kg CO2e',
    '%': '%',
  },
  change: 'Ändra',
  reward_block: {
    step_1_description:
      'Dina vänner får {{discount}} i rabatt på sin första order när de beställer via din länk.',
    step_1_title: 'Dela din referrallänk',
    title: 'Dela & tjäna bonuspoäng',
    share_button: 'Dela och tjäna bonuspoäng',
    step_2_description:
      'Du får {{discount}} rabatt varje gång dina vänner använder din kod. \n\nDu kan bjuda in max. 20 vänner genom din länk per år. ',
    step_2_title: 'Tjäna bonuspoäng',
  },
  ally_text: {
    change_subscription_configuration: 'Ändra inställningar',
    edit_address: 'Redigera adress',
    edit_subscription: 'ändra i beställningen',
  },
  recommended_option: 'Rekommenderat',
  subscription_configuration: {
    additional_products: 'Andra produkter',
    select_next_order: {
      heading: 'När vill du ha din nästa leverans',
    },
    x_kg_y_weeks: '{{kg}}kg var<br/> {{weeks}}e. vecka\n',
    explainer:
      '<div class="drop-letter">💡 Prova-på-paket är vårt prisvärda introduktionserbjudande till Alvars bindningsfria prenumeration. Nedan ser du detaljer för {{dogName}}s prenumeration. </div> Prenumerationen innehåller samma produkter som ditt prova på paket men du kan ändra eller avsluta prenumerationen när du vill  -helt utan bindningstid.\n',
  },
  checkout_pre_footer: {
    if_you_continue: 'Om du fortsätter',
    join_other_pupstomers:
      'Anslut dig till tusentals välmående och glada Alvarkunder',
    summary: 'Summering',
    tasting_package: 'Prova på paket',
    subscription_summary: 'Prenumeration Sammanfattning',
    this_order: 'Denna beställning',
  },
  coupon_modal: {
    discount_successfully_applied: 'Rabatten har lagts till på din beställning',
    no_applicable_product_in_sub:
      'Din prenumeration innehåller inga produkter som omfattas av rabatten. Du kan lägga till produkter här nedanför!',
  },
  discount_overview: {
    discount_applied: 'Rabatten tillämpad',
    discount_applies_to_cheapest: 'Rabatten gäller den billigaste produkten',
  },
  breeders: {
    box_content: {},
  },
  no_thanks: 'Nej tack',
  back: 'Tillbaka',
  link: {
    order_history: 'Beställningshistorik',
  },
  alt_text: {
    complexes: {
      digestion: 'Digestion+',
      joint_support: 'Joints & Mobility+',
      'mother_&_puppy': 'Mother & Puppy+\n',
      puppy: 'Puppy+',
      senior: 'Senior+',
      sensitivity: 'Sensitivity+',
      'skin_&_coat': 'Skin & Coat+',
      weight_control: 'Weight Control+',
    },
  },
  customer_service: {
    consultation: {
      body: 'Är du osäker på din hunds diet? Vår veterinär Henna kan hjälpa dig!',
      link_text: 'Boka en konsultation',
    },
    contact: {
      body: 'Om du behöver hjälp, finns {{name}} här för dig! Skicka ett meddelande till:',
      link_text: 'hello@alvarpet.com\n',
    },
    faq: {
      body: 'Om du har frågor om din beställning eller produkt kan du hitta svar på dina frågor på',
      link_text: 'Frågor & Svar -sidan',
    },
    heading: 'Kundsupport',
    trustpilot: {
      body: 'Är du nöjd med Alvar och vår service?\n',
      link_text: 'Ge oss ett omdöme på Trustpilot',
    },
  },
  product_option: {
    out_of_stock: 'Ej i lager',
  },
  validation: {
    invalid_shipping_country: 'Fel land',
  },
  subscription_card: {
    action: {
      activate: 'Aktivera',
      add_coupon: 'Rabattkod',
      delay_next_order: 'Skjut upp nästa leverans',
      pay_now: 'Betala nu',
      resolve_issue: 'Åtgärda',
    },
    status: {
      on_the_way: 'Beställningen är på väg och borde anlända inom 1-3 dagar.',
      cancelled: 'Prenumerationen är avslutad\n',
      next_delivery_scheduled_for: 'Nästa leverans: {{date}}',
      on_hold: {
        needs_payment:
          'Betalningen misslyckades - beställningen kan ej hanteras innan problemet är åtgärdat.',
        something_wrong:
          'Det är ett problem med prenumerationen, vänligen fyll i den saknade informationen.',
      },
    },
    notification: {
      you_unlocked_a_reward:
        'Du har uppnått en ny lojalitetsförmån för denna prenumeration.',
    },
  },
  subscription_list: {
    add_another_dog: 'Lägg till ytterligare en hund\n',
    heading: {
      order_status: 'Orderstatus',
    },
  },
  change_dog: {
    select_dog: 'Välj annan hund',
  },
  user: {
    start_by_adding_dog: 'Börja med att lägga till en hund',
    we_miss_u: 'Vi saknar dig',
    we_miss_u_lead:
      'Skulle det vara dags för en ny beställning åt vovven? Om det är något du undrar över, tveka inte att kontakta våran kundsupport, vi hjälper gärna!',
  },
  campaign_hero: {
    product_launch: {
      deer_treat_launch: {
        body: '100% naturligt hundgodis av torkad vild hjort 🦌 Inte bara supergoda utan också nyttiga och naturliga. Gjort av hållbara ingredienser från Sverige: Hjortkött och inget annat\n',
        title:
          "Vi presenterar stolt vårt nya hundgodis Alvar's Snack Wild Deer\n",
      },
      dental: {
        body: 'To celebrate the Alvar & Kivuton merge: new in! Choose from 3 alternatives, or test the full trio.',
        title: 'Add dental care products to your subscription',
      },
    },
  },
  modal: {
    delay_next_order: {
      heading: 'Skjut upp nästa leverans\n',
    },
  },
  cancellation_form: {
    not_suitable: {
      try_sensitivity_products: {
        include_kibble: {
          tooltip: {
            'ALV-106':
              'Delicate: spannmålsfritt foder med anka, medelstora foderbitar\n',
            'ALV-107':
              'Zippy: spannmålsfritt foder med kyckling och extra små foderbitar',
            'ALV-108':
              'Steady: spannmålsfritt foder med vildfisk. Medelstora foderbitar',
            'ALV-108-FI':
              'Steady: spannmålsfritt foder med vildfisk. Medelstora foderbitar\n',
            'ALV-110':
              'Bug: Spannmålsfritt foder med insektprotein. Medelstora foderbitar',
          },
        },
      },
    },
    not_tasty: {
      try_picky_products: {
        include_kibble: {
          tooltip: {
            'ALV-107':
              'Zippy är en favorit bland små kräsna hundar. Spannmålsfritt, kycklingbaserat och små foderkulor fullproppade med smak.\n',
            'ALV-108':
              'Steady är en favorit bland kräsna hundar. Spannmålsfritt foder med vildfisk som proteinkälla och medelstora foderbitar.\n',
          },
        },
      },
    },
  },
  subscription_switcher: {
    one_time_order: {
      heading: {
        save_on_orders: 'Spara på alla beställningar',
      },
    },
  },
  dog_info_title: 'Vovve-info',
  opt_in_modal: {
    body: 'Skicka skräddarsydda rekommendationer till min email.',
    close: 'Nej tack',
    heading: 'Rekommendationer till mailen?',
    submit: 'Ja tack!',
  },
  analytical_constituents: 'Analytiska beståndsdelar\n',
  ash: 'Aska',
  cart: {
    delivery_price: 'Leverans',
    total: 'Totalt',
  },
  close: 'Stäng',
  fat: 'Fett',
  fiber: 'Råfiber',
  protein: 'Protein',
  shipping_price: {
    calculated_at_checkout: 'beräknas i nästa steg',
  },
  subscription_status: {
    active: 'Aktiv',
    cancelled: 'Avslutad',
    on_hold: 'Inväntar vidare behandling',
  },
  calcium: 'Kalcium',
  magnesium: 'Magnesium',
  moisture: 'Fukt',
  omega_3: 'Omega-3',
  omega_6: 'Omega-6',
  phosphorus: 'Fosfor',
  potassium: 'Kalium',
  skip_order: {
    body: 'Din nästa beställning behandlas <b>{{nextOrder}}<b/> och levereras till dig inom 1-3 dagar.',
    heading: 'Är du säker?',
  },
  sodium: 'Natrium',
  order_date: 'Orderdatum',
};
